import { useState, createContext } from "react";
import Main from "./components/Main";
import Login from "./components/Login";
import Routes from "./routes";

export const EmailProvider = createContext(null);
export const PasswordProvider = createContext(null);

function App() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="App">
      <EmailProvider.Provider value={[email, setEmail]}>
        <PasswordProvider.Provider value={[password, setPassword]}>
          <Routes />
        </PasswordProvider.Provider>
      </EmailProvider.Provider>
      {/* <Login /> */}
      {/* <Main /> */}

      {/* {isLoaggedIn && <Main />} */}
    </div>
  );
}

export default App;
