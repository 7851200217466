import React, { useContext, useState } from "react";
import iconError from "../assets/images/icon-error.svg";
import logo from "../assets/images/logo.png";
import EmailValidator from "email-validator";

import { EmailProvider, PasswordProvider } from "../App";

import "./index.scss";

const validEmail = "user3002@lurer-archive.com";
const validPassword = "p3TaqCbq07XNJt0v4cMm";

const Login = () => {
  const [globalEmail, setGlobalEmail] = useContext(EmailProvider);
  const [globalPassword, setGlobalPassword] = useContext(PasswordProvider);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const submit = (event) => {
    event.preventDefault();

    !EmailValidator.validate(email)
      ? setErrorEmail(true)
      : setErrorEmail(false);
    password === "" ? setErrorPassword(true) : setErrorPassword(false);

    if (email !== validEmail) {
      setErrorEmail(true);
      return;
    }
    if (password !== validPassword) {
      setErrorPassword(true);
      return;
    }

    localStorage.setItem("email", email);
    localStorage.setItem("password", password);
    setGlobalEmail(email);
    setGlobalPassword(password);
  };

  return (
    <main className="login-main">
      <div className="login-wrapper">
        <a href="https://www.lurer.com">
          <img
            style={{
              width: "140px",
            }}
            alt="Lurer Archive Logo"
            src={logo}
          />
        </a>
        <div className="main-container">
          <div className="left-panel">
            <h1 className="left-panel__title">ՄՈՒՏՔ ԳՈՐԾԵԼ ԱՐԽԻՎ</h1>
            <p className="left-panel__description">
              Մուտք գործեք օգտագործելով տրամադրված տվյալները
            </p>
          </div>
          <div className="right-panel">
            <form className="right-panel__form" onSubmit={submit} noValidate>
              <div className="right-panel__form__section">
                <input
                  type="text"
                  id="email"
                  className={`right-panel__form__section__input ${
                    errorEmail ? "error" : ""
                  }`}
                  autoComplete="off"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Էլեկտրոնային հասցե"
                />

                {errorEmail && (
                  <div className="right-panel__form__section__icon-error">
                    <img src={iconError} alt="" className="icon-error" />
                  </div>
                )}
              </div>
              {errorEmail && (
                <div className="message-error">Սխալ էլեկտրոնային հասցե</div>
              )}

              <div className="right-panel__form__section">
                <input
                  type="password"
                  id="password"
                  className={`right-panel__form__section__input ${
                    errorPassword ? "error" : ""
                  }`}
                  autoComplete="off"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Գաղտնաբառ"
                />

                {errorPassword && (
                  <div className="right-panel__form__section__icon-error">
                    <img src={iconError} alt="" className="icon-error" />
                  </div>
                )}
              </div>
              {errorPassword && (
                <div className="message-error">Գաղտնաբառը դատարկ է</div>
              )}

              <button type="submit" className="right-panel__form__btn">
                Մուտք գործել
              </button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
