import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import Main from "../components/Main";
import Login from "../components/Login";
import { EmailProvider, PasswordProvider } from "../App";
import { mainPageRoute } from "../constants";

const MainRoutes = () => {
  const [globalEmail, setGlobalEmail] = useContext(EmailProvider);
  const [globalPassword, setGlobalPassword] = useContext(PasswordProvider);
  const [isOk, setIsOk] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const queryParameters = new URLSearchParams(window.location.search);

  useEffect(() => {
    const type = queryParameters.get("p");

    if (!type) {
      window.location.href = "https://lurer.com/";
      return null;
    }

    if (type != process.env.REACT_APP_QUERY_PARAM) {
      window.location.href = "https://lurer.com/";
      return null;
    }

    setIsOk(true);
  }, []);

  // useEffect(() => {
  //   if (globalEmail && globalPassword) {
  //     navigate(`/archive/post/${mainPageRoute}`);
  //   }
  // }, [globalEmail, globalPassword]);

  // useEffect(() => {
  //   const emailfromLocalStorage = localStorage.getItem("email");
  //   const passwordfromLocalStorage = localStorage.getItem("password");
  //   if (passwordfromLocalStorage && emailfromLocalStorage) {
  //     setGlobalEmail(emailfromLocalStorage);
  //     setGlobalPassword(passwordfromLocalStorage);
  //   }
  // }, []);

  if (!isOk) return <div />;
  return (
    <Routes>
      <Route exact path="/" element={<Main />} />
      {/* <Route exact path="/login" element={<Login />} /> */}

      {/* <Route exact path={`/archive/post/:id`} element={<Main />} /> */}

      <Route exact path="*" element={<Main />} />
    </Routes>
  );
};

export default MainRoutes;
